import React from 'react';
import { Link } from 'react-router-dom';
import '../globalstyle.css'
import HeaderSlideBar from './new_slidebar/slidebar';
const HeaderBanner = () => {
  return (
      <HeaderSlideBar />
  )
}

export default HeaderBanner